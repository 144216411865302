export const incomingMessageTypes = {
  gameConfigurationChanged: "gameConfigurationChanged",
  unlink: "unlink",
  codeResponse: "codeResponse",
  selectGameRequest: "selectGameRequest",
  startGame: "startGame",
  abortGame: "abortGame",
  throw: "throw",
  takeoutStarted: "takeoutStarted",
  takeoutFinished: "takeoutFinished",
  loadGame: "loadGame",
  playerListChanged: "playerListChanged",
  sbcStatusChanged: "sbcStatusChanged",
  sbcRegistrationError: "sbcRegistrationError",
  reconnect: "reconnect",
  linkResponse: "linkResponse",
  connect: "connect",
  updateRefreshToken: "updateRefreshToken",
};

export const outgoingMessageTypes = {
  codeRequest: "codeRequest",
  gameReady: "gameReady",
  gameOver: "gameOver",
  linkRequestWithToken: "linkRequestWithToken",
  reload: "reload",
  gameSceneChanged: "gameSceneChanged",
};
