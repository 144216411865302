import { Dispatch } from "redux";
import * as apiService from "../../services/api-service";
import { outgoingMessageTypes } from "../../constants/message-types";
import SbcStatus from "../../models/sbc-status";
import Player from "../../models/player";
import { GameScene } from "../../constants/game-event-types";
import Game from "../../models/game";
import {
  RESET,
  SELECT_GAME,
  SET_CODE,
  UPDATE_GAME_CONFIGURATION,
  UPDATE_GAME_LIST,
  SBC_STATUS_CHANGED,
  UPDATE_PLAYER_LIST,
  SET_GAME_SCENE,
  SET_TAKE_OVER_CONTROL,
} from "./action-types";

export const updateGameConfiguration = (values: any) => ({
  type: UPDATE_GAME_CONFIGURATION,
  values,
});
export const setCode = (code: string | null) => ({ type: SET_CODE, code });

export const reset = () => ({ type: RESET });

export const requestCode =
  () =>
  (dispatch: Dispatch, getState: Function, { emit }: { emit: Function }) => {
    dispatch(setCode(null));
    emit(outgoingMessageTypes.codeRequest);
  };

export const selectGame = (game: Game) => (dispatch: Dispatch) => {
  if (game) {
    dispatch({ type: SELECT_GAME, game });
  }
};

export const loadGames = () => async (dispatch: Dispatch) => {
  let games;

  try {
    games = await apiService.loadGameList();
  } catch (e) {
    console.log(e);
    games = [];
  }

  dispatch({
    type: UPDATE_GAME_LIST,
    gameList: games,
  });
};

export const sbcStatusChanged =
  ({ status }: SbcStatus) =>
  (dispatch: Dispatch) => {
    dispatch({ type: SBC_STATUS_CHANGED, status });
  };

export const updatePlayerList = (incomingPlayers: Player[]) => (dispatch: Dispatch) => {
  const players = incomingPlayers.map(({ name, image }) => ({
    name,
    image,
  }));

  dispatch({ type: UPDATE_PLAYER_LIST, players });
};

export const setGameScene = (gameScene: GameScene) => ({ type: SET_GAME_SCENE, gameScene });

export const setTakeOverControl = (isTakeOverControlVisible: boolean) => ({
  type: SET_TAKE_OVER_CONTROL,
  isTakeOverControlVisible,
});
