import { Box } from "@material-ui/core";
import React, { useCallback, useContext, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { GameList } from "../components/game-list";
import { SelectedGame } from "../components/selected-game";
import ApplicationState from "../models/application-state";
import { PlayerCard } from "../components/PlayerCard/player-card";
import { PlayersCarousel } from "../components/players-carousel";
import BoardStatus from "../components/Notification/BoardStatus/board-status";
import SocketContext from "../context/socket-context";
import { outgoingMessageTypes } from "../constants/message-types";

import { setGameScene } from "../store/actions/action-creators";

const mapStateToProps = (state: ApplicationState) => ({
  selectedGame: state.selectedGame,
  gameList: state.gameList,
  playerList: state.playerList,
});

export default function GameListScreen() {
  const { selectedGame, gameList, playerList } = useSelector((state: ApplicationState) => mapStateToProps(state));
  const [dimensions, setDimensions] = React.useState({ width: 0, height: 0 });
  const socket = useContext(SocketContext);
  const dispatch = useDispatch();

  const setDisplayGameScene = useCallback(() => {
    socket?.emit(outgoingMessageTypes.gameSceneChanged, "game_list");
    dispatch(setGameScene("game_list"));
  }, []);

  const updateDimensions = useCallback(() => {
    setDimensions({ width: window.innerWidth, height: window.innerHeight });
  }, []);

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  useEffect(() => {
    setDisplayGameScene();
  }, []);

  const setGameListPadding = useCallback(() => {
    let leftPaddingInPercent = 0;

    if (dimensions.width < 1360) {
      leftPaddingInPercent = (dimensions.width - 432 * 2) / 3 - 23.5;
      return leftPaddingInPercent + "px";
    } else if (dimensions.width <= 1438) {
      leftPaddingInPercent = 8.5;
      return leftPaddingInPercent + "px";
    } else if (dimensions.width < 1920) {
      leftPaddingInPercent = (dimensions.width - 432 * 3) / 4 - 23.5;
      return leftPaddingInPercent + "px";
    } else if (dimensions.width < 2380) {
      leftPaddingInPercent = (dimensions.width - 432 * 4) / 5 - 23.5;
      return leftPaddingInPercent + "px";
    } else if (dimensions.width >= 2380) {
      leftPaddingInPercent = (dimensions.width - 432 * 5) / 6 - 23.5;
      return leftPaddingInPercent + "px";
    }
  }, [dimensions.width]);

  return (
    <Box
      width={1}
      height="100vh"
      style={{
        backgroundImage: "url(" + process.env.PUBLIC_URL + "/game_list_background.png)",
        backgroundSize: "cover",
      }}
    >
      <BoardStatus />
      <Box width={1} height="80%" display="flex" flexDirection="row">
        <Box width="25%" display="flex" flexDirection="column" alignItems="center">
          <Box alignItems="center" display="flex" height="100%">
            <img src="scoliaLogo.png" alt="logo" width="350px" height="360px" />
          </Box>
          <Box alignItems="center" display="flex" flexDirection="column" width="100%" height="100%">
            {playerList.length > 5 && (
              <PlayersCarousel
                items={playerList.map((player, idx) => (
                  <PlayerCard key={idx} player={player} number={idx} />
                ))}
              />
            )}
            {playerList.length <= 5 &&
              playerList.map((player, idx) => <PlayerCard key={idx} player={player} number={idx} />)}
          </Box>
        </Box>
        <Box display="flex" flex="1" width="75%">
          <Box display="flex" flex="1" style={{ marginTop: "6%", marginRight: "4%", marginBottom: "2%" }}>
            <SelectedGame game={selectedGame} />
          </Box>
        </Box>
      </Box>
      <Box width={1} height="20%" display="flex" alignItems="center" style={{ paddingLeft: setGameListPadding() }}>
        <GameList selectedGame={selectedGame} games={gameList} screenWidth={dimensions.width} />
      </Box>
    </Box>
  );
}
