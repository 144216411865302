import { Box } from "@material-ui/core";
import React, { useEffect, useRef, useContext, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";
import ApplicationState from "../models/application-state";
import SocketContext from "../context/socket-context";
import GameService from "../services/game-service";
import { outgoingMessageTypes } from "../constants/message-types";
import BoardStatus from "../components/Notification/BoardStatus/board-status";
import { setGameScene } from "../store/actions/action-creators";

const GameScreen: React.FC = () => {
  const dispatch = useDispatch();
  const socket = useContext(SocketContext);
  const gameArea = useRef<HTMLIFrameElement>(null);
  const selectedGame = useSelector((state: ApplicationState) => state.selectedGame);

  const setDisplayGameScene = useCallback(() => {
    socket?.emit(outgoingMessageTypes.gameSceneChanged, "in_game");
    dispatch(setGameScene("in_game"));
  }, []);

  useEffect(() => {
    GameService.setGameWindow(gameArea.current?.contentWindow);
    GameService.onGameReady(() => socket?.emit(outgoingMessageTypes.gameReady));
    GameService.onGameOver(() => socket?.emit(outgoingMessageTypes.gameOver));
  }, []);

  useEffect(() => {
    setDisplayGameScene();
  }, []);

  if (!selectedGame) {
    return <Navigate to="/" />;
  }

  return (
    <Box width={1} overflow="hidden">
      <BoardStatus />
      <Box position="relative">
        <iframe
          ref={gameArea}
          title="game-area"
          src={selectedGame.path}
          style={{
            border: 0,
            padding: 0,
            margin: 0,
            width: "100%",
            height: "100vh",
          }}
        />
      </Box>
    </Box>
  );
};

export default GameScreen;
