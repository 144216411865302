import GameConfiguration from "../models/game-configuration";
import {
  CONFIGURATION_CHANGED,
  GAME_READY,
  GAME_OVER,
  THROW_DETECTED,
  TAKEOUT_STARTED,
  TAKEOUT_FINISHED,
  START_GAME,
  PLAYER_LIST_CHANGED,
} from "../constants/game-event-types";
import Player from "../models/player";

type MessagePayload = string | number | object | [];
type GameMessage = { type: string; payload?: MessagePayload };

export class GameService {
  private static instance: GameService;
  private gameWindow: Window | null | undefined = null;
  private gameReadyCallback: () => void = () => undefined;
  private gameOverCallback: () => void = () => undefined;
  private messageListener: EventListener = (event: Event) => this.receiveMessage(event as MessageEvent);
  private playerList: Player[] = [];

  private constructor() {}

  public static getInstance(): GameService {
    if (!GameService.instance) {
      GameService.instance = new GameService();
    }

    return GameService.instance;
  }

  public updateGameConfiguration(configuration: GameConfiguration): void {
    this.sendMessage(CONFIGURATION_CHANGED, configuration);
  }

  private sendMessage(type: string, payload?: MessagePayload) {
    this.gameWindow?.postMessage(JSON.stringify({ type, payload }), window.location.origin);
  }

  public setGameWindow(window?: Window | null): void {
    this.gameWindow = window;
    this.registerEventListener();
  }

  private registerEventListener(): void {
    window.addEventListener("message", this.messageListener);
  }

  private receiveMessage(event: MessageEvent): void {
    if (event.origin !== window.location.origin) {
      return;
    }

    try {
      const message: GameMessage = JSON.parse(event.data);
      this.handleMessage(message);
    } catch (error) {
      console.error("Error parsing message from game", error);
    }
  }

  private handleMessage(message: GameMessage): void {
    switch (message.type) {
      case GAME_READY:
        this.gameReadyCallback();
        this.playerListChanged(this.playerList);
        break;
      case GAME_OVER:
        this.gameOverCallback();
        break;
      default:
        break;
    }
  }

  public onGameReady(callback: () => void): void {
    this.gameReadyCallback = callback;
  }

  public onGameOver(callback: () => void): void {
    this.gameOverCallback = callback;
  }

  public throwDetected(payload: object): void {
    this.sendMessage(THROW_DETECTED, payload);
  }

  public takeoutStarted(payload: object): void {
    this.sendMessage(TAKEOUT_STARTED, payload);
  }

  public takeoutFinished(payload: object): void {
    this.sendMessage(TAKEOUT_FINISHED, payload);
  }

  public startGame(): void {
    this.sendMessage(START_GAME);
  }

  public playerListChanged(players: Player[]): void {
    this.sendMessage(PLAYER_LIST_CHANGED, players);
  }

  public updatePlayerList(players: Player[]): void {
    this.playerList = players;
  }

  public reset(): void {
    window.removeEventListener("message", this.messageListener);
    this.gameWindow = null;
    this.gameReadyCallback = this.gameOverCallback = () => undefined;
  }
}

export default GameService.getInstance();
