import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import cn from "classnames";
import { getSbcStatus } from "../../../store/selectors";

import "./board-status.scss";

const statusMessages = {
  offline: "Board is currently offline",
  online: "Board is online",
  in_use: "",
};

const BoardStatus = () => {
  const [showBoardStatus, setShowBoardStatus] = useState(false);
  const sbcStatus = useSelector(getSbcStatus);

  useEffect(() => {
    if (sbcStatus === "online") {
      setTimeout(() => setShowBoardStatus(false), 3000);
    } else if (sbcStatus === "offline") {
      setShowBoardStatus(true);
    }
  }, [sbcStatus]);

  return (
    <div
      className={cn("board-status-overlay", {
        "animate-in": showBoardStatus,
        "animate-out": !showBoardStatus,
        offline: sbcStatus === "offline",
        online: sbcStatus === "online",
      })}
    >
      {statusMessages[sbcStatus]}
    </div>
  );
};

export default BoardStatus;
