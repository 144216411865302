import { Reducer } from "redux";
import { RESET, SET_GAME_SCENE } from "../actions/action-types";

const gameScene: Reducer = (state = "start_screen", action) => {
  switch (action.type) {
    case SET_GAME_SCENE:
      return action.gameScene;
    case RESET:
      return "start_screen";
    default:
      return state;
  }
};

export default gameScene;
