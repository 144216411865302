import { Reducer } from "redux";
import { SET_TAKE_OVER_CONTROL } from "../actions/action-types";

const takeOverControl: Reducer = (state = false, action) => {
  switch (action.type) {
    case SET_TAKE_OVER_CONTROL:
      return action.isTakeOverControlVisible;
    default:
      return state;
  }
};

export default takeOverControl;
