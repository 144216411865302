import ApplicationState from "../../models/application-state";

export const getFilteredGameList = (state: ApplicationState) =>
  state.gameList.filter(({ gameId }) => !state.selectedGame || gameId !== state.selectedGame.gameId);

export const getScreenshots = (state: ApplicationState) => state.gameList.map((game) => game.screenshots).flat();

export const getSbcStatus = (state: ApplicationState) => state.sbcStatus;

export const getTakeOverControlVisibility = (state: ApplicationState) => state.isTakeOverControlVisible;
